import api from './baseApi';

const driverApi = {
  getDrivers(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/drivers?${childPath}`);
  },
  getDriverDetail(id) {
    return api.get(`/drivers/${id}`);
  },
  postDriver(data) {
    return api.post('/drivers', data);
  },

  updateDriver(data, id) {
    return api.patch(`/drivers/${id}`, data);
  },

  deleteDriver(id) {
    return api.delete(`/drivers/${id}`);
  },

  validateDriverAttribute(data) {
    return api.post(`/drivers/validate-attribute`, data);
  },
};

export default driverApi;
