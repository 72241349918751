/* eslint-disable react/no-unstable-nested-components */
import React, { memo } from 'react';
import { IconSearch } from '@assets/icons';
import { LAYOUT_WRAPPER_FULL } from '@common/constant/form';
import { MyDatePicker, MySelect } from '@components/common';
import masterDataApi from '@services/apis/masterData';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  TimePicker,
} from 'antd';
import PropTypes from 'prop-types';
import {
  FieldDriverCode,
  FieldUploadFileMaster,
  FormAvatarEdit,
  FormItemUpload,
} from './FormItem/index';

function FormItem({
  name,
  label,
  rules,
  itemType = 'input',
  listOptions,
  borderless = false,
  inputWidth,
  disabled,
  layoutForm,
  disabledBeforeDate,
  placeholder,
  helper,
  handleOnSearch,
  dependencies,
  textSearch,
  isEdit,
  ...props
}) {
  const form = Form.useFormInstance();

  if (
    !layoutForm &&
    [
      'radio',
      'checkbox',
      'multi',
      'multi2',
      'daterange',
      'datetime',
      'upload',
    ].includes(itemType)
  ) {
    layoutForm = LAYOUT_WRAPPER_FULL;
  }

  const handleSearchPostCd = async () => {
    const prefix = name?.replace('PostCd', '');
    const postCd = form.getFieldValue(name);
    if (!postCd) {
      return;
    }
    await masterDataApi
      .getAddresses(postCd)
      .then((res) => {
        const addresses = res?.results?.at(0);
        if (addresses)
          form.setFieldsValue({
            [`${prefix}Address1`]: `${addresses?.address1}${addresses?.address2}`,
            [`${prefix}Address2`]: addresses?.address3,
          });
      })
      .catch((e) => console.log(e));
  };

  const itemRender = {
    input: () => (
      <Input
        style={{ width: inputWidth }}
        disabled={disabled}
        placeholder={placeholder}
        {...props}
      />
    ),
    memo: () => (
      <Input.TextArea
        style={{ width: inputWidth }}
        disabled={disabled}
        {...props}
      />
    ),
    password: () => (
      <Input.Password
        className="input-password"
        style={{ width: inputWidth }}
        size="large"
        variant="outlined"
        iconRender={(visible) =>
          visible ? 'パスワードを非表示' : 'パスワードを表示'
        }
        disabled={disabled}
        {...props}
      />
    ),
    post: () => (
      <Row style={{ flexFlow: 'row' }}>
        <Form.Item
          label={label}
          labelCol={{ prefixCls: 'w-0' }}
          name={name}
          rules={rules}
          className="w-215"
        >
          <Input style={{ width: inputWidth }} disabled={disabled} {...props} />
        </Form.Item>
        <Button
          type="primary"
          className="ml-10"
          disabled={disabled}
          onClick={handleSearchPostCd}
        >
          <IconSearch />
        </Button>
      </Row>
    ),
    search: () => (
      <Row style={{ flexFlow: 'row' }} align="middle">
        <Form.Item
          label={label}
          labelCol={{ prefixCls: 'w-0' }}
          name={name}
          rules={rules}
          className="w-215"
        >
          <Input
            style={{ width: inputWidth }}
            disabled={disabled}
            placeholder={placeholder}
            {...props}
          />
        </Form.Item>
        <span
          className="ml-20 color-primary fw-400 fz-16 pointer"
          onClick={handleOnSearch}
        >
          {textSearch || '郵便番号検索'}
        </span>
      </Row>
    ),
    select: () => (
      <MySelect
        style={{ width: inputWidth }}
        listOptions={listOptions}
        disabled={disabled}
        {...props}
      />
    ),
    radio: () => (
      <Radio.Group disabled={disabled} {...props}>
        <Row gutter={[0, 8]}>
          {listOptions?.map(({ value, label: labelRadio }) => (
            <Col key={value}>
              <Radio className="pr-8" key={value} value={value}>
                {labelRadio}
              </Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    ),
    checkbox: () => (
      <Checkbox.Group disabled={disabled} {...props}>
        {listOptions?.map(({ value, label: labelCheckbox }) => (
          <Checkbox className="pr-8" key={value} value={value}>
            {labelCheckbox}
          </Checkbox>
        ))}
      </Checkbox.Group>
    ),
    multi: () => (
      <Row>
        <Form.Item
          label={label}
          labelCol={{ prefixCls: 'w-0' }}
          name={name?.at(0)}
          rules={rules?.at(0)}
          className="mr-20"
          style={{ width: inputWidth }}
        >
          <Input className="w-215" />
        </Form.Item>
        {name?.at(1) ? (
          <Form.Item colon label="単位" name={name?.at(1)} rules={rules?.at(1)}>
            <MySelect className="w-120" listOptions={listOptions} />
          </Form.Item>
        ) : (
          <div className="flex-center h-48 pos-abs" style={{ left: '235px' }}>
            kg
          </div>
        )}
      </Row>
    ),
    multi2: () => (
      <Row>
        <Form.Item
          label={label}
          labelCol={{ prefixCls: 'w-0' }}
          name={name?.at(0)}
          rules={rules?.at(0)}
          className="mr-20"
          style={{ width: inputWidth }}
        >
          <Input className="w-215" />
        </Form.Item>

        <div className="flex-center h-48 pos-abs" style={{ left: '235px' }}>
          cm
        </div>
      </Row>
    ),
    datepicker: () => (
      <MyDatePicker
        style={{ width: inputWidth }}
        disabled={disabled}
        disabledBeforeDate={disabledBeforeDate}
        {...props}
      />
    ),
    datetime: () => (
      <Row>
        <Form.Item name={name?.at(0)} rules={rules?.at(0)}>
          <MySelect listOptions={listOptions} className="w-215 mr-16" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() =>
            // show rangePicker when user pick timeDiv is "時間を指定"
            form.getFieldValue(name?.at(0)) === '02' && (
              <Form.Item
                name={name?.at(1)}
                rules={rules?.at(1)}
                labelCol={{ prefixCls: 'w-0' }}
                label={label}
              >
                <TimePicker.RangePicker format="HH:mm" className="w-240" />
              </Form.Item>
            )
          }
        </Form.Item>
      </Row>
    ),
    daterange: () => (
      <Row>
        <Form.Item
          label={label}
          labelCol={{ prefixCls: 'w-0' }}
          name={name?.at(0)}
          rules={rules?.at(0)}
          className="mr-16"
        >
          <MyDatePicker className="w-215" />
        </Form.Item>
        <p className="fz-24 h-48 flex-center">~</p>
        <Form.Item
          label={label}
          labelCol={{ prefixCls: 'w-0' }}
          name={name?.at(1)}
          rules={rules?.at(1)}
          className="ml-16"
        >
          <MyDatePicker className="w-215" />
        </Form.Item>
      </Row>
    ),
    timeRange: () => (
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label={label}
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(0)}
            rules={rules?.at(0)}
          >
            <TimePicker
              placeholder={placeholder?.at(0)}
              showNow={false}
              format="HH:mm"
              {...props}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <p className="fz-24 flex-center">~</p>
        </Col>
        <Col span={11}>
          <Form.Item
            label={label}
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(1)}
            rules={rules?.at(1)}
          >
            <TimePicker
              placeholder={placeholder?.at(1)}
              showNow={false}
              format="HH:mm"
              {...props}
            />
          </Form.Item>
        </Col>
      </Row>
    ),
    timeAttendances: () => (
      <Form.Item
        label={label}
        labelCol={{ prefixCls: 'w-0' }}
        wrapperCol={{ flex: '150px' }}
        name={name}
        rules={rules}
      >
        <TimePicker placeholder={placeholder} format="HH:mm" />
      </Form.Item>
    ),
    multiInput: () => (
      <Row gutter={[20]}>
        <Col span={disabled ? 9 : 12}>
          <Form.Item
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(0)}
            rules={rules?.at(0)}
            style={{ width: inputWidth }}
          >
            <Input {...props} placeholder={placeholder?.at(0)} />
          </Form.Item>
        </Col>
        <Col span={disabled ? 9 : 12}>
          <Form.Item
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(1)}
            rules={rules?.at(1)}
            style={{ width: inputWidth }}
          >
            <Input placeholder={placeholder?.at(1)} />
          </Form.Item>
        </Col>
        {disabled && (
          <Col span={6} className="flex-align">
            <Button
              onClick={handleOnSearch}
              type="primary"
              className="h-32 flex-center"
            >
              地図表示
            </Button>
          </Col>
        )}
      </Row>
    ),
    textarea: () => (
      <Input.TextArea
        style={{ width: inputWidth }}
        disabled={disabled}
        {...props}
      />
    ),
    addressBaseInput: () => (
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label={label}
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(0)}
            rules={rules?.at(0)}
          >
            <Input
              style={{ width: inputWidth }}
              disabled={disabled}
              {...props}
              placeholder={placeholder?.at(0)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Button type="link" disabled={disabled} onClick={handleOnSearch}>
            住所検索
          </Button>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(1)}
            rules={rules?.at(1)}
            style={{ width: inputWidth }}
          >
            <Input {...props} placeholder={placeholder?.at(1)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(2)}
            rules={rules?.at(2)}
            style={{ width: inputWidth }}
          >
            <Input {...props} placeholder={placeholder?.at(2)} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            labelCol={{ prefixCls: 'w-0' }}
            name={name?.at(3)}
            rules={rules?.at(3)}
            style={{ width: inputWidth }}
          >
            <Input {...props} placeholder={placeholder?.at(3)} />
          </Form.Item>
        </Col>
      </Row>
    ),
    upload: () => <FormItemUpload name={name} {...props} isEdit={isEdit} />,
    fieldUploadFile: () => <FieldUploadFileMaster name={name} {...props} />,
    avatarEdit: () => <FormAvatarEdit name={name} {...props} />,
    fieldDriverCode: () => <FieldDriverCode name={name} {...props} />,
  };

  return (
    <Form.Item
      colon={false}
      labelAlign="left"
      {...layoutForm}
      label={label}
      required={false}
      name={
        ![
          'datetime',
          'daterange',
          'timeRange',
          'timeAttendances',
          'multiInput',
          'addressBaseInput',
        ].includes(itemType)
          ? name
          : undefined
      }
      className={`form-item ${borderless ? 'borderless' : ''}`}
      rules={!['post', 'search'].includes(itemType) && rules}
      help={helper}
      dependencies={dependencies}
    >
      {itemRender[itemType]() ?? null}
    </Form.Item>
  );
}

export default memo(FormItem);

FormItem.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemType: PropTypes.oneOf([
    'input',
    'memo',
    'password',
    'select',
    'radio',
    'checkbox',
    'datepicker',
    'datetime',
    'daterange',
    'multi',
    'multi2',
    'post',
    'timeRange',
    'timeAttendances',
    'multiInput',
    'textarea',
    'addressBaseInput',
    'upload',
    'search',
    'fieldUploadFile',
    'avatarEdit',
    'fieldDriverCode',
  ]),
  listOptions: PropTypes.array,
  borderless: PropTypes.bool,
  rules: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  disabled: PropTypes.bool,
  layoutForm: PropTypes.object,
  disabledBeforeDate: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  textSearch: PropTypes.string,
  helper: PropTypes.string,
  handleOnSearch: PropTypes.func,
  dependencies: PropTypes.array,
  isEdit: PropTypes.bool,
  initialCompanyCodes: PropTypes.array,
};
