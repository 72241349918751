import React from 'react';
import {
  DRIVER_ATTRIBUTE,
  DRIVER_ATTRIBUTE_CODE_TYPE,
} from '@common/constant/masterData';
import driverApi from '@services/apis/driver';
import { Form, Input, Radio, Space } from 'antd';
import PropTypes from 'prop-types';

function FieldDriverCode({ name, ...props }) {
  const {
    driverId,
    initialDriverAttributeCodeType,
    initialCompanyCode,
    initialDriverCode,
    ...restProps
  } = props;

  const attributeRules = [
    () => ({
      async validator({ field }, value) {
        if (value) {
          try {
            const response = await driverApi.validateDriverAttribute({
              driverId,
              attribute:
                field === 'driverCode'
                  ? DRIVER_ATTRIBUTE.DRIVER_CODE
                  : DRIVER_ATTRIBUTE.COMPANY_CODE,
              value,
            });

            if (response.status === true) {
              return Promise.resolve();
            }

            return Promise.reject(
              new Error(response.message || '予期せぬエラーが発生しました。'),
            );
          } catch (err) {
            return Promise.reject(new Error('予期せぬエラーが発生しました。'));
          }
        }

        return Promise.resolve();
      },
    }),
  ];

  return (
    <Radio.Group
      name={name}
      {...restProps}
      defaultValue={initialDriverAttributeCodeType}
    >
      <Space direction="vertical" size="middle">
        <Space align="start">
          <Radio
            value={DRIVER_ATTRIBUTE_CODE_TYPE.COMPANY_CODE}
            className="w-100 mt-15"
          >
            社番
          </Radio>
          <Form.Item
            name="companyCode"
            colon="false"
            layout="inline"
            initialValue={initialCompanyCode}
            rules={attributeRules}
            style={{ width: 200 }}
          >
            <Input />
          </Form.Item>
        </Space>
        <Space align="start">
          <Radio
            value={DRIVER_ATTRIBUTE_CODE_TYPE.DRIVER_CODE}
            className="w-100 mt-15"
          >
            乗組員ID
          </Radio>
          <Form.Item
            name="driverCode"
            colon="false"
            layout="inline"
            initialValue={initialDriverCode}
            rules={attributeRules}
            style={{ width: 200 }}
          >
            <Input />
          </Form.Item>
        </Space>
      </Space>
    </Radio.Group>
  );
}

export default FieldDriverCode;

FieldDriverCode.propTypes = {
  driverId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  initialDriverAttributeCodeType: PropTypes.string,
  initialCompanyCode: PropTypes.string,
  initialDriverCode: PropTypes.string,
};
