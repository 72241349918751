import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconSiderCollapse, IconSiderLogo } from '@assets/icons';
import { PATH_URL_PC } from '@common/config/pathURL';
import { ADMINS, ALL_ADMINS, LOCAL_ADMINS } from '@common/constant/auth';
import { useLayout } from '@common/contexts/Layout';
import useWindowSize from '@common/hooks/useWindowSize';
import { Layout, Menu, Row } from 'antd';
import PropTypes from 'prop-types';

function SideBar({ profile }) {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, setCollapsed } = useLayout();
  const { height } = useWindowSize();

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  useEffect(() => {
    if (!profile) return;
    const { authRoleDiv } = profile;
    const tmp = [
      getItem('荷物情報照会', PATH_URL_PC.ORDER_BARCODE_LIST),
      ADMINS.includes(authRoleDiv) &&
        getItem('注文一覧', PATH_URL_PC.ORDER_LIST),
      LOCAL_ADMINS.includes(authRoleDiv) &&
        getItem('配送設定', PATH_URL_PC.ASSIGN_CREATE),
      LOCAL_ADMINS.includes(authRoleDiv) &&
        getItem('運行管理', PATH_URL_PC.OP_MANAGEMENT),
      LOCAL_ADMINS.includes(authRoleDiv) &&
        getItem('運行一覧', PATH_URL_PC.LADIND_LIST),
      (ADMINS.includes(authRoleDiv) || ADMINS.includes(authRoleDiv)) &&
        getItem('その他メニュー ', '6', null, [
          ADMINS.includes(authRoleDiv) &&
            getItem('定期集配一覧', PATH_URL_PC.REGULAR_LIST),
          ADMINS.includes(authRoleDiv) &&
            getItem('精算一覧', PATH_URL_PC.CALCULATION_LIST),
          LOCAL_ADMINS.includes(authRoleDiv) &&
            getItem('稼働車両状況', PATH_URL_PC.OPMONITOR),
          ADMINS.includes(authRoleDiv) && getItem('分析', PATH_URL_PC.CHART),
          ADMINS.includes(authRoleDiv) &&
            getItem('勤怠管理', PATH_URL_PC.ATTENDANCE_LIST),
        ]),
      ALL_ADMINS.includes(authRoleDiv) &&
        getItem('マスタ設定', '7', null, [
          ALL_ADMINS.includes(authRoleDiv) &&
            getItem('ユーザマスタ', PATH_URL_PC.USER_MASTER),
          ADMINS.includes(authRoleDiv) &&
            getItem('拠点マスタ', PATH_URL_PC.BASE_MASTER),
          ADMINS.includes(authRoleDiv) &&
            getItem('顧客マスタ', PATH_URL_PC.CUSTOMER_MASTER),
          ADMINS.includes(authRoleDiv) &&
            getItem('運送会社マスタ', PATH_URL_PC.COMPANY_MASTER),
          LOCAL_ADMINS.includes(authRoleDiv) &&
            getItem('配送枠マスタ', PATH_URL_PC.SLOTS_MASTER),
          LOCAL_ADMINS.includes(authRoleDiv) &&
            getItem('配送員マスタ', PATH_URL_PC.DRIVER_MASTER),
          LOCAL_ADMINS.includes(authRoleDiv) &&
            getItem('車両マスタ', PATH_URL_PC.CAR_MASTER),
        ]),
    ];
    setItems(tmp);
  }, [profile]);

  const navigateToHome = () => {
    navigate(PATH_URL_PC.HOME);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClickMenu = (e) => {
    navigate(e.key);
  };
  const pathNameSnippets =
    location.pathname === '/'
      ? location.pathname
      : `/${location.pathname
          .split('/')
          .filter((i) => i)
          .at(0)}`;

  return (
    <Layout.Sider
      collapsed={collapsed}
      className="layout-wraper-sider"
      style={{ height }}
      width={240}
    >
      <Row justify="space-between" align="middle" className="sider-logo">
        <IconSiderLogo className="icon-logo" onClick={navigateToHome} />
        <IconSiderCollapse
          className="icon-collapsed"
          onClick={toggleCollapsed}
        />
      </Row>
      <Menu
        onClick={onClickMenu}
        mode="inline"
        items={items}
        className="sider-menu"
        selectedKeys={[pathNameSnippets]}
      />
    </Layout.Sider>
  );
}

export default SideBar;

SideBar.propTypes = {
  profile: PropTypes.object,
};
